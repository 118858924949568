/* globals zc _ app */

(function () {
  'use strict'

  zc.views.PricingPageView = zc.views.PageView.extend({
    initialize: function (options) {
      zc.views.PageView.prototype.initialize.call(this, options)
    },

    className: 'pricing-page page',

    template: _.template($('.pricing-page-template').html()),

    planSelectCallback: function () {
      app.router.navigate('/dashboard', {trigger: true})
    },

    renderPlans: function () {
      var plansView = new zc.views.PlansView({
        model: new zc.models.Billing({couponCode: this.model.get('couponCode'), interval: this.model.get('interval')}),
        el: this.$plans,
        showCouponForm: true,
        callback: this.planSelectCallback,
        signUpOnlyMode: !!this.model.get('signUpOnlyMode')
      })
      plansView.render()
    },

    renderHeader: function () {
      this.recordingHeaderView = new zc.views.ProjectHeaderView({ configType: 'login', model: this.model, el: this.$header })
      this.recordingHeaderView.render()
    },

    render: function () {
      this._render()

      this.$el.html(this.template(this.model.toJSON()))
      this.$plans = this.$('.plans')

      this.$header = this.$('.header-container')
      this.renderHeader()
      this.renderPlans()

      return this
    }
  })
})()
