/* globals zc _ Backbone app analytics */

(function () {
  'use strict'

  zc.views.AppView = zc.views.BaseView.extend({
    initialize: function () {
      _.bindAll(this, 'initWaitlist')
      this.listenTo(this.model, 'change:location', this.switchLocation)

      $('.app').delegate('.video-header-link', 'click', this.initWaitlist)
    },

    template: _.template($('.app-template').html()),

    className: 'app',

    navLink: function (e) {
      // navigate link as single page app
      if (Backbone.History.started) {
        e.preventDefault()
        app.router.navigate(e.target.pathname, {trigger: true})
        analytics.page()
      }
    },

    switchLocation: function (app, location) {
      if (app.location) {
        app.location.trigger('exit')
      }

      app.location = location
      var locationView = new location.View({model: location})

      location.view = locationView
      $('.app').append(locationView.render().el)
      location.trigger('enter')
    },

    initWaitlist: function () {
      window.location.href = 'https://beta.zencastr.com'
    },

    render: function () {
      this._render()
      return this
    }

  })
})()
