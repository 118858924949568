/* globals zc Backbone */

(function () {
  'use strict'

  zc.models.AnimationFrameManager = Backbone.Model.extend({
    initialize: function (attrs) {
      this.setInterval()
      this.on('change:fps', this.fpsChange)
    },

    defaults: {
      fps: 60,
      optimizedFps: 25
    },

    interval: null,
    rafId: null, // id of active requestAnimationFrame
    animations: [],

    setInterval: function () {
      this.interval = 1000 / this.get('fps')
    },

    fpsChange: function () {
      this.setInterval()
    },

    optimize: function () {
      this.set({fps: this.defaults.optimizedFps})
    },

    unoptimize: function () {
      this.set({fps: this.defaults.fps})
    },

    addAnimation: function (animation) {
      if (this.animations.indexOf(animation) > -1) return // block duplicate animations from being added
      this.animations.push(animation)
      if (!this.rafId) this.startAnimationFrames()
    },

    removeAnimation: function (animation) {
      var idx = this.animations.indexOf(animation)
      if (idx >= 0) {
        this.animations.splice(idx, 1)
      }
      if (!this.animations.length) this.stopAnimationFrames()
    },

    startAnimationFrames: function () {
      var fps = this.get('fps')
      this.interval = 1000 / fps
      this.draw()
    },

    stopAnimationFrames: function () {
      cancelAnimationFrame(this.rafId)
      this.rafId = undefined
    },

    draw: function () {
      var self = this

      var now = performance.now()

      // always request a subsequent frame and draw
      self.rafId = window.requestAnimationFrame(function () {
        self.draw()
      })

      // only draw if its time to
      if (!self.lastDrawAt || now - self.lastDrawAt >= self.interval) {
        self.animations.forEach(function (animation) {
          animation.draw(animation.context)
        })

        self.lastDrawAt = now
      }
    },

    timeoutDraw: function () {
      var self = this
      // console.log(this.rafId)
      this.timeoutId = setTimeout(function () {
        self.rafId = window.requestAnimationFrame(function () {
          self.draw()
        })

        self.animations.forEach(function (animation) {
          animation.draw(animation.context)
        })
      }, self.interval)
    }
  })
})()
