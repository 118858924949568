/* globals zc Backbone utils */

(function () {
  zc.views.BaseView = Backbone.View.extend({
    _render: function (directOptions) {
      directOptions = directOptions || {}
      var modelAttrs = this.model.attrs ? this.model.attrs() : this.model.toJSON()
      var globalTmplFunctions = {
        cdnUrl: utils.cdnUrl.bind(this)
      }
      this.$el.html(this.template(Object.assign({}, directOptions, globalTmplFunctions, modelAttrs)))
      return this
    }
  })
})()
