/* globals zc _ utils jwtClient $ */

(function () {
  'use strict'

  zc.views.EmailVerificationPageView = zc.views.PageView.extend({
    initialize: function (options) {
      zc.views.PageView.prototype.initialize.call(this, options)

      this.sendVerification()
    },

    template: _.template($('.email-verification-page-template').html()),

    className: 'email-verification-page',

    events: {
      'click .resend-email': 'sendVerification'
    },

    sendVerification: function () {
      /** @type {import('@zencastr/jwt-fetch-client')} */
      (jwtClient).fetchWithJwt('/api/email-verification', {
        method: 'POST'
      }).then(function (res) {
        if (res.ok) {
          utils.notify('success', 'Verification Email Sent')
        } else {
          utils.notify('error', 'There was a problem sending the verification email. Please contact support.  ' + res.status)
        }
      }, function (err) {
        console.error('Verification email send error', err)
        utils.notify('error', 'There was a problem sending the verification email. Please contact support.')
      })
    },

    render: function () {
      this.$el.html(this.template(this.model))
      this.$header = this.$('.header-container')
      this.headerView = new zc.views.ProjectHeaderView({ configType: 'dashboard', model: this.model, el: this.$header })
      this.headerView.render()
      return this
    }
  })
})()
