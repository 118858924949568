/* globals servars zc zc2 _ utils Backbone app analytics */

(function () {
  'use strict'

  zc.views.GreenroomView = zc.views.BaseView.extend({
    initialize: function () {
      this.project = this.model
      this.userMedia = this.project.userMedia

      this.listenTo(this.project, 'exit', this.teardown)
      this.listenTo(app.user, 'change:cameraConnected', this.cameraConnectedChange)
      this.listenTo(this.project.lobby.users, 'change add remove change:isVoipConnected', this.renderParticipantNames)

      _.bindAll(this, 'hidePermissionsTakeover', 'onVideoInputSelected')

      this.reduxWatcher = new zc2.utils.ReduxWatcher(zc2.store)
      this.reduxWatcher.watch('media_manager.devices', this.checkIfMicIsPresent.bind(this))

      this.userMedia.on('streamAvailable', this.onVideoInputSelected)
    },

    template: _.template($('.greenroom-template').html()),

    className: 'greenroom takeover',

    events: {
      'submit form': 'submit'
    },

    joinCall: function (username, displayName) {
      var project = this.project
      app.user.set({ username, displayName, greenroom: false })
      // save to local storage so we can reuse on refresh
      localStorage.setItem('displayName', displayName)

      project.View = zc.views.ProjectView

      // render the project view
      app.trigger('change:location', app, project)

      // start call if more people are in the room
      if (project.lobby.users.length > 1) {
        project.call.connect().catch(function (error) {
          console.error(error)
          utils.notify('error', error.message, {}, 'An error has been encountered')
        })
      }

      app.socket.emit('voip:ready', app.user.toJSON())

      this.movingBgView.stopAnimation()

      analytics.track('JoinRecordingAttempted', {
        guestId: app.user.id,
        displayName: displayName,
        recordingId: app.project.recorder.recording.id,
        projectId: app.project.id
      })
    },

    askForMediaPermissions: function () {
      var self = this
      return new Promise(function (resolve, reject) {
        var clearPermissionsTimeout = function () {
          if (self.showPermissionsTimeout) {
            clearTimeout(self.showPermissionsTimeout)
          }
          self.hidePermissionsTakeover()
        }
        // only show the back media permissions modal if necessary
        self.showPermissionsTimeout = setTimeout(function () {
          self.showPermissionsTakeover()

          analytics.track('PermissionsAsked', {
            guestId: app.user.id,
            recordingId: app.project.recorder.recording.id,
            projectId: app.project.id,
            permissions: app.project.recorder.recording.get('videoRecordingMode') === 'disabled' ? 'just mic' : 'mic + cam'
          })
        }, 800)

        self.project.startDevices().then(function () {
          analytics.track('PermissionsGranted', {
            guestId: app.user.id,
            recordingId: app.project.recorder.recording.id,
            projectId: app.project.id,
            permissions: app.project.recorder.recording.get('videoRecordingMode') === 'disabled' ? 'just mic' : 'mic + cam'
          })

          clearPermissionsTimeout()

          resolve()
        }).catch(function (err) {
          self.handleMediaDeviceError()
          clearPermissionsTimeout()
          reject(err)
        })
      })
    },

    handleMediaDeviceError: function () {
      this.renderMicSelect()
      this.renderCameraSelect()
      this.renderAudioOutView()

      this.checkIfMicIsPresent()

      if (!this.userMedia.devices.getDevicesByKind('audiooutput').length) {
        this.audioOutView.setError()
      }

      if (!this.userMedia.devices.getDevicesByKind('videoinput').length) {
        this.cameraSelectView.setError()
      }
    },

    showPermissionsTakeover: function () {
      this.$permissionsBg.show()
    },

    hidePermissionsTakeover: function () {
      this.$permissionsBg.hide()
    },

    validateNickname: function (login) {
      if (!utils.validateNickname(login)) {
        return 'Your name should be <= 30 alphanumeric characters'
      }
    },

    validateForm: function (attrs) {
      var validNicknameError = this.validateNickname(attrs.username)
      if (validNicknameError) {
        return validNicknameError
      }

      return false
    },

    values: function () {
      return {
        displayName: this.$username.val(),
        username: utils.sanitizeUserName(utils.deAccentify(this.$username.val()))
      }
    },

    teardown: function () {
      this.cameraSelectView.teardown()
      this.reduxWatcher.destroy()
      this.remove()
    },

    submit: function (e) {
      e.preventDefault()
      var values = this.values()
      var validationError = this.validateForm(values)

      if (validationError) {
        return this.showFormValidationError(validationError)
      }

      this.joinCall(values.username, values.displayName)
    },

    onVideoInputSelected: function (localStream) {
      // dont try and render the video if there are no video inputs
      if (!this.userMedia.devices.getDevicesByKind('videoinput').length) {
        return
      }

      if (this.videoView) {
        this.videoView.streamAdded(null, localStream)
      } else {
        this.videoView = new zc.views.UserVideoView({
          model: app.user,
          mediaStream: localStream,
          // mediaEl: mediaEl,
          id: app.user.id || '123',
          animatedBackground: true
        })
        this.$('.video-group').append(this.videoView.render().el)
        this.$el.addClass('camera-showing')
      }
    },

    showFormValidationError: function (errorMessage) {
      var self = this
      var keyUpHandler = function (e) {
        self.$username.removeClass('error')
          .attr('placeholder', '')
          .off('keyup', keyUpHandler)
      }
      this.$username
        .addClass('error')
        .text('')
        .blur()
        .attr('placeholder', errorMessage)
        .on('keyup', keyUpHandler)
    },

    renderMicSelect: function () {
      if (this.micSelectView) return
      this.micSelectView = new zc.views.MediaDeviceSelectView({
        devices: this.userMedia.devices,
        mediaType: 'audioIn',
        customSwitchName: 'mic-switch'
      })
      this.micSelectView.render()
      this.$('.mic-container').append(this.micSelectView.el)
    },

    renderCameraSelect: function () {
      if (this.cameraSelectView) return

      this.cameraSelectView = new zc.views.MediaDeviceSelectView({
        devices: this.userMedia.devices,
        mediaType: 'videoIn',
        customSwitchName: 'cam-switch'
      })
      this.cameraSelectView.render()
      this.$('.camera-container').append(this.cameraSelectView.el)
    },

    renderAudioOutView: function () {
      if (this.audioOutView) return

      this.audioOutView = new zc.views.MediaDeviceSelectView({
        devices: this.userMedia.devices,
        mediaType: 'audioOut',
        customSwitchName: 'no-toggle'
      })
      this.audioOutView.render()
      this.$('.speaker-container').append(this.audioOutView.el)
    },

    renderHeader: function () {
      var headerView = new zc.views.GuestHeaderView({ model: this.project, headerType: 'greenrooom' })
      headerView.render()
      headerView.setRecordingStatus()
      this.$('.header-container').append(headerView.$el)
    },

    renderMovingBackground: function () {
      this.movingBgView = new zc.views.MovingBackgroundView({ model: new Backbone.Model({ background: true }) })
      this.movingBgView.render()
      this.movingBgView.startAnimation()
      this.$el.append(this.movingBgView.$el)
    },

    roomIsFull: function () {
      var participantCount = app.project.lobby.users.getVoipConnected().length
      var maxCount = servars.roomSize
      return participantCount >= maxCount
    },

    renderParticipantNames: function () {
      // Room is full
      if (this.roomIsFull()) {
        this.$participantNames.text('Max Participants are in the room')
        this.$participantNames.addClass('danger')
        this.$el.find('button.join').attr('disabled', 'true')
        return
      } else {
        this.$participantNames.removeClass('danger')
        this.$el.find('button.join').removeAttr('disabled')
      }
      // Get all usernames that are not us
      var participantNames = app.project.lobby.users.filter(function (user) {
        return user.id !== app.user.id
      }).map(function (user) {
        return user.get('displayName')
      })

      var newHtml
      if (participantNames.length) {
        if (participantNames.length > 1) {
          var allButLastParticipant = participantNames.slice(0, participantNames.length - 1)
          var lastParicipant = participantNames[participantNames.length - 1]
          newHtml = '<b>' + allButLastParticipant.join('</b>, <b>') + '</b> and <b>' + lastParicipant + '</b> are in the room'
        } else {
          newHtml = '<b>' + participantNames[0] + '</b> is in the room'
        }
      } else {
        newHtml = 'Nobody is in the room.'
      }
      this.$participantNames.html(newHtml)
    },

    cameraConnectedChange: function (model, cameraConnected) {
      if (!cameraConnected && this.videoView) {
        this.videoView.remove()
      } else if (this.call && this.call.localMedia) {
        // getLocalMedia only if we already have requested a stream from gUM
        // we want this because this event might get triggered before calling gUM
        this.call.getLocalMedia()
      }
    },

    checkIfMicIsPresent: function () {
      if (!this.userMedia.devices.getDevicesByKind('audioinput').length) {
        if (this.micSelectView) {
          this.micSelectView.setError()
        }

        this.$submit.attr('disabled', true).addClass('disabled')
      } else {
        this.$submit.attr('disabled', false).removeClass('disabled')
      }
    },

    render: function () {
      this._render({
        username: localStorage.getItem('displayName') || '',
        videoRecordingMode: this.project.recorder.recording.get('videoRecordingMode')
      })
      this.renderMovingBackground()
      this.$submit = this.$('button[type="submit"]')
      this.$username = this.$('#username')
      this.$permissionsBg = this.$('.permissions-bg')
      this.$username = this.$('#username')
      this.$participantNames = this.$('.footer-content .participants')

      if (app.user.get('username') !== 'guest') {
        this.$username.val(app.user.get('displayName'))
      }

      analytics.track('GreenroomShown', {
        guestId: app.user.id,
        recordingId: app.project.recorder.recording.id,
        projectId: app.project.id
      })
      /*
       * We need to defer the call to askForMediaPermissions to ensure that camera connected state is properly communicate
       */
      this.project.roomJoined.then(function () {
        this.askForMediaPermissions().then(function () {
          this.renderHeader()
          this.renderMicSelect()
          this.renderCameraSelect()
          this.renderAudioOutView()
          this.$username.focus()
          $('.app').removeClass('page-loading')
        }.bind(this))
      }.bind(this))

      return this
    }
  })
})()
