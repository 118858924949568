/* globals zc _ $ Backbone servars app analytics */

(function () {
  'use strict'

  /**
   * model: zc.models.Billing
   */
  zc.views.PlansView = Backbone.View.extend({
    initialize: function (options) {
      this.callback = options.callback
      this.showCouponForm = options.showCouponForm

      this.model.set('signUpOnlyMode', options.signUpOnlyMode)
      this.listenTo(this.model, 'loading', this.showLoading)
      this.listenTo(this.model, 'doneLoading', this.hideLoading)
      this.listenTo(this.model, 'change:interval', this.render)
      this.listenTo(this.model, 'change:coupon', this.render)

      this.initSignupOnly()
    },

    className: 'plans',

    template: _.template($('.plans-template').html()),

    events: {
      'click .interval-switch-box': 'toggleInterval',
      'click .professional .subscribe': 'subscribeClick',
      'click .hobbyist .subscribe': 'hobbyistSubscribeClick'
    },
    initSignupOnly: function () {
      var params = new URLSearchParams(window.location.search)
      var signup = this.model.get('signUpOnlyMode')
      var plan = params.get('plan')

      if (signup) {
        if (plan && plan.length) {
          // you should only be able to see the
          // sign up modal as a non logged in user
          if (!app.user) {
            this.subscribe(plan, true)
          } else {
            window.location.href = '/pricing'
          }
        }
      }
    },

    toggleInterval: function (e) {
      e.stopPropagation() // stop propagation to prevent race condition where the modal was closing
      var interval = this.model.get('interval')
      var newInterval = interval === 'year' ? 'month' : 'year'
      this.model.set('interval', newInterval)
    },

    hobbyistSubscribeClick: function (e) {
      var self = this
      var $target = $(e.target)
      var planId = $target.closest('.plan').attr('id')

      var attrs = this.model.attrs()

      // if the user already has an account and it's not on the hobbyist plan
      // show a special modal
      // this asks for feedback when downgrading
      if (!attrs.canTrial && attrs.currentPlanId !== 'hobbyist' && attrs.currentPlanId !== 'hobby_pro_trial') {
        self.renderCancelConfirmModal(planId)
      } else {
        self.subscribe(planId)
      }
    },

    initCancellationInsights: function (planId) {
      var self = this

      return new Promise(function (resolve, reject) {
        var barecancelConf = servars.baremetrics.barecancel
        window.barecancel = window.barecancel || {
          created: false,
          params: {
            access_token_id: barecancelConf.accessToken, // Your Cancellation API public key
            test_mode: barecancelConf.testMode,
            comment_required: barecancelConf.commentRequired,
            reason_mandatory: true,
            customer_oid: app.user.subscription.get('customer'),
            subscription_oids: [app.user.subscription.get('billingId')], // Array of subscription IDs you're looking to cancel
            callback_send: function (data) {
              console.log(data)
              var reason = _.findWhere(data.reasons, { id: data.selected_reason })
              // if an error appeared with baremetrics, data.reasons might be empty
              reason = reason ? reason.text : data.selected_reason
              var comment = data.comment
              self.downgradePlan({ planId: planId, reason: reason, comment: comment })
            },
            callback_error: function (errData) {
              console.error(errData)
              self.downgradePlan({ planId: planId })
            }
          }
        }

        if (window.barecancel && window.barecancel.created) {
          // already loaded
          resolve(window.barecancel)
        } else {
          var a = document.createElement('script')
          a.src = 'https://baremetrics-barecancel.baremetrics.com/js/application.js'
          a.async = !0
          var b = document.getElementsByTagName('script')[0]
          b.parentNode.insertBefore(a, b)
          a.onload = function () {
            window.barecancel.created = true
            resolve(window.barecancel)
          }
          a.onerror = reject
        }
      })
    },

    renderCancelConfirmModal: function (planId) {
      var self = this

      this.initCancellationInsights(planId).then(function () {
        // show modal once barecancel script is loaded
        var interval = setInterval(function () {
          if (!window.barecancel.loading) {
            clearInterval(interval)
            window.barecancel.open()
          }
        }, 500)
      }).catch(function (err) {
        console.error(err)
        self.downgradePlan({ planId: planId })
      })
    },

    downgradePlan: function (args) {
      var data = {
        user: app.user,
        planId: args.planId,
        reason: args.reason,
        comment: args.comment,
        subscriptionId: app.user.subscription.id
      }

      this.updateSubscription(data)
    },

    subscribeClick: function (e) {
      var $target = $(e.target)
      var planId = $target.closest('.plan').attr('id')
      this.subscribe(planId)
    },

    showLoading: function () {
      this.$subscribe.addClass('disabled')
    },

    hideLoading: function () {
      this.$subscribe.removeClass('disabled')
    },

    renderSignupModal: function (callback, stayOpen, planId) {
      this.signupModalView = new zc.views.ModalView({
        addClass: 'signup-modal',
        noBlurBackground: true,
        ChildView: zc.views.SignupFormView,
        callback: callback,
        stayOpen: stayOpen,
        selectedPlan: this.model.getPlanById(planId),
        signUpOnlyMode: this.model.get('signUpOnlyMode'),
        couponCode: this.model.get('couponCode')
      })
      this.signupModalView.render()
    },

    subscribe: function (planId, stayOpen = false) {
      var view = this
      if (!app.user) {
        this.renderSignupModal(function (err, user) {
          if (err) return
          view.submitSubscription(app.user, planId)
        }, stayOpen, planId)
      } else {
        view.submitSubscription(app.user, planId)
      }
    },

    submitSubscription: function (user, planId) {
      var self = this
      var sub = user.subscription

      var canTrial = sub.canTrial()
      var hasSubscription = sub.has('billingId')
      var trialEnd = canTrial ? undefined : 'now'
      var plan = this.model.getPlanById(planId)
      var isFreePlan = !Number(plan.amount)

      var data = {
        user: user,
        trialEnd: trialEnd,
        planId: planId
      }

      var needsPaymentSource
      if (hasSubscription) { // has active subscription/trial
        // @TODO: change this logic back to the commented out code in the future
        // after the video launch is over and the 6 week free trials have expired
        // needsPaymentSource = !isFreePlan && !user.get('paymentSource')
        needsPaymentSource = sub.trialing() && !user.get('paymentSource')
        data.subscriptionId = user.subscription.id
        if (needsPaymentSource) {
          this.getPaymentSource(function (source) {
            data.source = source
            self.updateSubscription(data)
          })
        } else {
          self.updateSubscription(data)
        }

        var trackVars = {
          value: plan.amount,
          name: plan.name
        }
        if (sub.plan && sub.plan.get('name')) {
          trackVars.oldPlan = sub.plan.get('name')
        }
        analytics.track('Change Plan', trackVars)
      } else { // is new or inactive and needs a new sub created
        needsPaymentSource = !isFreePlan && !user.get('paymentSource') && !canTrial
        if (needsPaymentSource) {
          this.getPaymentSource(function (source) {
            data.source = source
            self.createSubscription(data)
          })
        } else {
          self.createSubscription(data)
        }

        analytics.track('RegisteredUser', {
          value: plan.amount,
          name: plan.name,
          trialEnd: trialEnd
        })
      }
    },

    createSubscription: function (data) {
      var self = this
      return this.model.createSubscription(data).then(function (subscription) {
        app.user.subscription = new zc.models.Subscription(subscription, { user: app.user })
        self.callback(null, subscription)
      })
    },

    updateSubscription: function (data) {
      var self = this
      return this.model.updateSubscription(data).then(function (subscription) {
        app.user.subscription.set(subscription)
        self.callback(null, subscription)
      })
    },

    getPaymentSource: function (cb) {
      this.getTokenViaPaymentSourceForm(cb)
    },

    getTokenViaPaymentSourceForm: function (cb) {
      this.model.createPaymentSourceForm(cb)
        .open({ email: app.user && app.user.get('email') })
    },

    showSubscribed: function () {
      var plan = app.user.subscription.plan
      this.$('#' + plan.id).addClass('subscribed')
    },

    showTrialing: function () {
      var plan = app.user.subscription.plan
      this.$('#' + plan.id).addClass('trialing')
    },

    showSubscriptionState: function () {
      if (!app.user) return
      var sub = app.user.subscription
      var isActive = sub.isActive()
      var trialing = sub.trialing()
      if (trialing) {
        this.showTrialing()
      } else if (isActive) {
        this.showSubscribed()
      }
    },

    renderCouponForm: function () {
      var self = this
      var couponForm = new zc.views.CouponFormView({
        el: this.$couponForm,
        model: this.model,
        callback: function (err, coupon) {
          if (err || !coupon) {
            console.error('Error applying coupon', err)
            return couponForm.showError('Error applying coupon code')
          }

          self.model.set({ coupon: coupon })
        }
      })

      // render the couponForm first before we potentially auto-submit it
      couponForm.render()

      var couponCode = this.model.get('couponCode')
      var coupon = this.model.get('coupon')

      // if have a couponCode but not a coupon, then submit to fetch the coupon
      if (couponCode && !coupon) {
        couponForm.submit()
      }
    },

    render: function () {
      var attrs = this.model.attrs()
      this.$el.html(this.template(attrs))
      this.$subscribe = this.$('.subscribe')
      this.$couponForm = this.$('.coupon-form')

      this.showSubscriptionState()

      if (this.showCouponForm) {
        this.renderCouponForm()
      }

      return this
    }
  })
})()
