/* globals zc zc2 Backbone app trackJs servars sessionstack */

(function () {
  'use strict'

  zc.models.App = Backbone.Model.extend({
    initialize: function (attrs) {
      var model = this
      // Make app available before attempting to set user; so users are able to reference app while being constructed
      window.app = this
      this.setUser(this, attrs.user)
      this.animationFrameManager = new zc.models.AnimationFrameManager()
      this.tabProtectionService = zc2.services.TabProtectionService
      this.cloudDrive = null

      // start listening for changes on the user model
      this.on('change:user', this.setUser)

      if (window.trackJs) {
        trackJs.configure({
          onError: function (payload, err) {
            // var error = model.buildError(err)

            // wrap in a try catch so we don't accidentally break error reporting
            // by hitting an error while building the error meta
            try {
              var errorMeta = model.buildErrorMeta(err)

              // throw intentionally
              // var x = err.nothere.throw

              var key
              for (key in errorMeta) {
                if (errorMeta.hasOwnProperty(key)) {
                  payload.metadata.push({key: key, value: errorMeta[key]})
                }
              }

              if (servars.sessionstack && servars.sessionstack.enabled) {
                sessionstack('getSessionId', function (sessionId) {
                  if (sessionId) {
                    payload.metadata.push({
                      key: 'SessionStack URL',
                      value: 'https://app.sessionstack.com/player/#/sessions/' + sessionId
                    })
                  }
                })
              }
            } catch (err) {
              // store the unexpected error on the payload so it is tracked
              payload.errorWhileProcessingError = err.message + ' ' + err.stack
            }

            // model.logError(_.extend({}, error, errorMeta))
            return true
          }
        })
      }
    },

    // don't let a page spam errors
    errorsLogged: 0,
    // errorLogLimit: 100,

    defaults: {
      dropboxAuth: false
    },

    router: new zc.routers.Main(),

    start: function () {
      // start the app
      Backbone.history.start({pushState: true, hashChange: false})
    },

    setUser: function (model, user) {
      if (user) {
        var isModelInstance = !!user.cid

        var userModel = isModelInstance ? user : new zc.models.User(user)

        this.user = userModel
        this.user.billing = new zc.models.Billing({email: this.user.get('email')})
        zc2.store.dispatch(zc2.actions.users.CREATE_LOCAL_USER({
          id: userModel.id,
          muted: false,
          cameraOn: true,
          cameraConnected: true,
          settings: userModel.get('settings')
        }))

        this.user.addWatchers()
      }
    },

    handleError: function (err) {
      this.errorsLogged++

      if (window.trackJs) {
        trackJs.track(err)
      }
    },

    parseUnthrownError: function (err) {
      // for when we need to log an err that doesn't get thrown
      // this parses out the message, file, and line number
      if (!(err instanceof Error)) {
        err = new Error(err)
      }

      var parsed = {}
      parsed.message = err.message
      parsed.file = err.stack.split('\n')[1].match(/[^ ]+$/)[0]
      parsed.line = parsed.file.match(/([0-9]+):[0-9]+/)[1]
      parsed.stack = err.stack
      return parsed
    },

    buildError: function (err) {
      var error = {}

      error.href = window.location.href
      error.message = err.message
      error.file = err.file
      error.line = err.line
      error.column = err.column
      error.stack = err.stack

      return error
    },

    buildErrorMeta: function () {
      var location = this.location
      var errorMeta = {}

      errorMeta.version = servars.version

      if (app.user) {
        errorMeta.userId = app.user.id
        errorMeta.username = app.user.get('username')
      }

      if (location && location.get('name') && location.get('owner')) {
        // is project
        errorMeta.projectId = location.id
        errorMeta.projectOwner = location.get('owner')
        errorMeta.projectSlug = location.get('slug')
        errorMeta.projectName = location.get('name')

        if (location.recorder && location.recorder.recording) {
          var recorder = location.recorder
          var recording = recorder.recording
          errorMeta.recordingId = recording.id
          errorMeta.recordingDuration = recording.get('duration')
          errorMeta.recordingStarted = recorder.hasStartedRecording()
          errorMeta.recordingFinished = recorder.hasFinishedRecording()
        }
      }

      return errorMeta
    },

    logError: function (error) {
      if (app.socket && app.socket.connected) {
        app.socket.emit('frontendError', error)
      }
    }

  })
})()
