/* globals zc zc2 Backbone app utils */

(function () {
  'use strict'

  zc.models.Settings = Backbone.Model.extend({
    initialize: function (attrs, options) {
      this.user = options.user
      // if we should show the notification on save
      this.showNotification = true

      this.on('change', this.settingsChanged)
    },

    defaults: {
      wavRecording: false,
      hostVoip: true,
      soundboard: false,
      echoCancellation: true,
      videoRecordingResolution: {
        value: 1080,
        forced: false
      }
    },

    attrs: function () {
      return this.toJSON()
    },

    settingsChanged: function (model) {
      if (this.user.isLocal()) {
        if ('echoCancellation' in model.changed) {
          zc2.store.dispatch(zc2.actions.users.UPDATE_LOCAL_USER_ECHOCANCELLATION(model.changed['echoCancellation']))
        }

        if ('videoRecordingResolution' in model.changed) {
          zc2.store.dispatch(zc2.actions.users.UPDATE_LOCAL_USER_VIDEORESOLUTION(model.changed['videoRecordingResolution']))
        }
      }

      if (this.user.isHost() || window.location.pathname === '/dashboard') {
        // if videoRecordingResolution has changed, don't show a popup on save
        if (model.changed.videoRecordingResolution) {
          this.showNotification = false
        }

        this.save()
      }
    },

    save: function () {
      var self = this
      var data = {userId: this.user.id, settings: this.attrs()}
      app.socket.emit('user:update:settings', data, function (err) {
        // if we don't want so show a notification, just return
        // only do it once
        if (!self.showNotification) {
          self.showNotification = true
          return
        }

        if (err) {
          utils.notify('error', err)
        } else {
          utils.notify('success', 'Settings saved successfully', {ttl: 1500})
        }
      })
    }
  })
})()
