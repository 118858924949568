/* globals zc _ SineWaves */

(function () {
  'use strict'

  zc.views.LandingPageView = zc.views.PageView.extend({
    initialize: function (options) {
      zc.views.PageView.prototype.initialize.call(this, options)
    },

    template: _.template($('.landing-page-template').html()),

    className: 'landing-page page',

    events: {
      'click .video-beta': 'mainCtaClick',
      'click .cta-click': 'mainCtaClick'
    },

    mainCtaClick: function (e) {
      e.preventDefault()
      window.location.href = '/signup'
    },

    startSineWaves: function () {
      var self = this
      var calcHeight = function () {
        var blockHeight = self.$('.div-block-4').outerHeight()
        // shorten height so waveform can be seen in mobile
        return $(window).width() <= 479 ? blockHeight - 110 : blockHeight
      }
      return new SineWaves({
        el: document.getElementById('sine-waves'),

        speed: 4,

        width: function () {
          return self.$('.div-block-4').width()
        },

        height: function () {
          return calcHeight()
        },

        ease: 'SineInOut',

        wavesWidth: '70%',

        waves: [
          {
            timeModifier: 4,
            lineWidth: 1,
            amplitude: -25,
            wavelength: 25,
            strokeStyle: 'rgba(126, 126, 126, 0.2)'
          },
          {
            timeModifier: 2,
            lineWidth: 2,
            amplitude: -50,
            wavelength: 50,
            strokeStyle: 'rgba(103, 255, 182, 0.2)'
          },
          {
            timeModifier: 1,
            lineWidth: 1,
            amplitude: -100,
            wavelength: 100,
            strokeStyle: 'rgba(0, 163, 255, 0.2)'
          },
          {
            timeModifier: 0.5,
            lineWidth: 1,
            amplitude: -200,
            wavelength: 200,
            strokeStyle: 'rgba(179, 133, 255, 0.2)'
          },
          {
            timeModifier: 0.25,
            lineWidth: 1,
            amplitude: -150,
            wavelength: 150,
            strokeStyle: 'rgba(114, 145, 255, 0.2)'
          }
        ],

        // Called on window resize
        resizeEvent: function () {
          calcHeight()
        }
      })
    },

    render: function () {
      var self = this
      this._render()
      this.$madeOnZencastr = this.$('.made-on-zencastr')
      this.madeOnZencastr = new zc.views.MadeOnZencastr()
      this.$madeOnZencastr.append(this.madeOnZencastr.render().$el)

      _.defer(function () {
        self.startSineWaves()
      }, 100)

      return this
    }
  })
})()
