/* globals zc _ app */

(function () {
  'use strict'

  zc.views.SignupPageView = zc.views.PageView.extend({
    initialize: function (options) {
      zc.views.PageView.prototype.initialize.call(this, options)
      _.bindAll(this, 'signupCallback')
    },

    template: _.template($('.signup-page-template').html()),

    className: 'signup-page page',

    renderSignupModal: function () {
      this.signupModalView = new zc.views.ModalView({
        addClass: 'signup-modal',
        force: true,
        noBlurBackground: true,
        ChildView: zc.views.SignupFormView,
        callback: this.signupCallback.bind(this)
      })
      this.signupModalView.render()
    },

    signupCallback: function (err, user) {
      if (err) return console.log(err)

      this.signupModalView.exit()
      // window.session.loggedIn = true
      // window.session.user = user
      app.router.navigate('/dashboard', {trigger: true})
    },

    renderHeader: function () {
      this.recordingHeaderView = new zc.views.ProjectHeaderView({ configType: 'login', model: this.model, el: this.$header })
      this.recordingHeaderView.render()
    },

    render: function () {
      this.$el.html(this.template(this.model.toJSON()))
      this.$header = this.$('.header-container')

      this.renderSignupModal()

      return this
    }
  })
})()
