/* globals zc _ Backbone utils Plyr moment analytics */
(function () {
  'use strict'

  zc.views.MadeOnZencastr = Backbone.View.extend({
    selectedCreator: undefined,
    allVideos: [],

    initialize: function (options) {
      this.buildFeaturedVideos()
      this.selectCreator()
    },

    events: {
      'click .featuring-list': 'selectNewCreator',
      'click .pause': 'swallowPauseEvent',
      'touchend .featuring-wrapper': 'swallowPauseEvent'
    },

    template: _.template($('.made-on-zencastr-template').html()),

    buildFeaturedVideos: function () {
      const videoSource = window.servars.featuredCreators.creators || {}
      const now = Date.now()

      // turn {} into []
      var creatorList = Object
        .keys(videoSource)
        // create a list of objects with live Date's for future filtering
        .map(function (creatorName) {
          var sourceCreator = videoSource[creatorName]
          var returnCreator = Object.assign({}, sourceCreator)

          returnCreator.start = moment(sourceCreator.start).toDate()
          returnCreator.end = moment(sourceCreator.end).toDate()

          return returnCreator
        })
        // hidden creators only appear on the day they are active
        .filter(function (creator) {
          return !creator.hidden ? true : now >= creator.start && now <= creator.end
        }) || []

      creatorList.sort(function (c1, c2) { return c1.order - c2.order })

      this.allVideos = creatorList
    },

    showPauseButton: function () {
      this.paused = true
      this.$pauseButton.show()
      analytics.track('HomepageVideoPlayer', {
        creator: this.selectedCreator.id
      })
    },

    hidePauseButton: function () {
      this.paused = false
      this.$pauseButton.hide()
    },

    swallowPauseEvent: function (e) {
      e.preventDefault()
      if (this.paused) {
        this.plyr.play()
      } else {
        this.plyr.pause()
      }
    },

    /**
     * Selects the appropriate video of the day, or whichever one the 'featuring' link is pointing to
     */
    selectCreator: function (forcedCreatorId) {
      var now = Date.now()
      var queryParams = utils.getQueryStringParams()
      var featuredCreatorString = forcedCreatorId || (queryParams['featuring'] ? queryParams['featuring'].toLowerCase() : undefined)
      var featuredCreator = featuredCreatorString ? this.allVideos.find(function (creator) {
        return creator.id === featuredCreatorString
      }) : undefined

      // ?featuring=$creatorId can be used to force display of a video
      if (featuredCreator) {
        this.selectedCreator = featuredCreator
        return
      }

      // find the creator that is active today
      featuredCreator = this.allVideos.find(function (creator) {
        return now >= creator.start && now <= creator.end
      })

      if (!featuredCreator) {
        console.log('featured creator not found based on date, defaulting to first creator')
        featuredCreator = this.allVideos[0]
      }
      this.selectedCreator = featuredCreator
    },

    selectNewCreator: function (e) {
      if (!e.target.classList.contains('creatorThumbnail')) return
      this.$el.find('.featuring-list').children().removeClass('active')
      $(e.target).addClass('active')

      var creatorId = e.target.getAttribute('data-creator')

      this.selectCreator(creatorId)

      // update the image and link attribution
      this.$coverLink.attr('href', this.selectedCreator.showUrl)
      this.$coverLink.children('img').attr('src', this.selectedCreator.cover)

      // update the video
      /* https://github.com/sampotts/plyr#the-source-setter */
      this.plyr.source = {
        type: 'video',
        sources: [{src: this.selectedCreator.video}]
      }

      this.$el.find('.plyr__video-wrapper')
          .attr('style', 'background-image: url(' + this.selectedCreator.preview + ') !important')

      this.$video = this.$('video')
      this.$video.attr('data-poster', this.selectedCreator.preview)
      this.$video.on('pause', this.showPauseButton.bind(this))
      this.$video.on('play', this.hidePauseButton.bind(this))
      this.paused = true
      this.showPauseButton()
    },

    render: function () {
      if (this.allVideos && this.allVideos.length) {
        this.$el.html(this.template({
          allCreators: this.allVideos,
          cdnUrl: utils.cdnUrl,
          selected: Object.assign({}, this.selectedCreator)
        }))
        this.paused = true
        this.$video = this.$('video')
        this.$video.on('pause', this.showPauseButton.bind(this))
        this.$video.on('play', this.hidePauseButton.bind(this))
        this.$coverLink = this.$('.cover-link')
        this.plyr = new Plyr(this.$video, {
          controls: ['progress'],
          iconPrefix: 'zc-plyr',
          muted: false,
          volume: 1
        })
        this.$pauseButton = this.$('.pause')
      } else {
        this.$el.html('<div></div>')
      }
      return this
    }
  })
})()
