/* globals zc _  */

(function () {
  'use strict'

  zc.views.UnsupportedBrowserView = zc.views.PageView.extend({

    template: _.template($('.unsupported-browser-template').html()),

    className: 'unsupported-browser-page',

    renderHeader: function () {
      this.recordingHeaderView = new zc.views.ProjectHeaderView({ configType: 'login', model: this.model, el: this.$header })
      this.recordingHeaderView.render()
    },

    render: function () {
      this._render()
      this.$header = this.$('.header-container')
      this.renderHeader()

      return this
    }
  })
})()
