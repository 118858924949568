/* globals zc Backbone */

(function () {
  'use strict'

  zc.collections.Tracks = Backbone.Collection.extend({
    model: zc.models.Track,

    getTracksForUser: function (userId) {
      return this.filter(function (track) {
        return track.get('userId') === userId && track.get('type') !== 'soundboard'
      })
    }
  })
})()
