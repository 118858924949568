/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.views.PopupView = Backbone.View.extend({
    initialize: function (options) {
      this.$trigger = options.trigger
      this.callback = options.callback
      this.template = this.template || options.template

      _.bindAll(this, 'bodyClick')
      $('body').click(this.bodyClick)
    },

    className: 'popup',

    bodyClick: function (e) {
      var $target = $(e.target)
      if ($target.is(this.$trigger) || $target.closest(this.$trigger).length) {
        e.preventDefault()
        this.toggle()
      } else if (!$target.closest(this.$el).length) {
        if (this.$el.is(':visible')) {
          this.exit()
        }
      }
    },

    exit: function () {
      if (_.isFunction(this.callback)) {
        this.callback()
      }
      this.remove()
    },

    toggle: function () {
      if (this.$el.is(':visible')) {
        this.exit()
      } else {
        this.render()
      }
    },

    center: function () {
      var marginLeft = -(this.$content.outerWidth() / 2)
      var marginTop = -(this.$content.outerHeight() / 2)
      this.$content.css({marginLeft: marginLeft, marginTop: marginTop})
    },

    render: function () {
      if (!this.$el.parent().length) {
        var attrs = this.model ? this.model.attrs() : {}
        this.$el.html(this.template(attrs))
        $('body').append(this.$el)
      }
      this.$content = this.$('.content')

      this.$el.show()
      return this
    }
  })
})()
