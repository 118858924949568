/* globals zc Backbone app utils */

(function () {
  'use strict'

  zc.models.Stats = Backbone.Model.extend({
    initialize: function (attrs, options) {
      this.user = options.user
    },

    defaults: {
      usedCredits: 0
    },

    fetch: function () {
      var self = this
      var data = {userId: this.user.id}
      app.socket.emit('user:read:stats', data, function (err, stats) {
        if (err) return utils.notify('error', err)

        self.set(stats)
      })
    }
  })
})()
