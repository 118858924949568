/* globals zc _ Backbone utils analytics */

(function () {
  'use strict'

  zc.views.LoginPageView = zc.views.PageView.extend({
    initialize: function (options) {
      zc.views.PageView.prototype.initialize.call(this, options)
      _.bindAll(this, 'loginCallback')
    },

    template: _.template($('.login-page-template').html()),

    className: 'login-page page',

    renderLoginForm: function () {
      this.childView = new zc.views.LoginFormView({
        addClass: 'login-modal',
        callback: this.loginCallback
      })
      this.$('.login-form').append(this.childView.render().el)
    },

    newTosConfirm: function (cb) {
      var title = 'The Terms of Service have been updated'
      var text = 'Please confirm that you have read and agree to the new <a href="/terms-of-service">Terms of Service</a> and <a href="/privacy-policy">Privacy Policy</a> to continue.'
      var confirmText = 'I Agree'
      var cancelText = 'No, Thanks'

      this.confirmTosModalView = new zc.views.ModalView({
        addClass: 'confirm-tos-modal',
        model: new Backbone.Model({ title: title, text: text, confirmText: confirmText, cancelText: cancelText }),
        ChildView: zc.views.ConfirmView,
        force: true,
        callback: cb
      })

      this.confirmTosModalView.render()
    },

    loginCallback: function (err, user) {
      if (err) return

      var coupon = this.model.get('couponCode')
      var dashboardUrl = '/dashboard'

      analytics.alias(user._id)
      analytics.identify(user._id, {
        name: user.displayName,
        username: user.username,
        email: user.email,
        plan: user.subscription && user.subscription.plan && user.subscription.plan.billingId,
        createdAt: user.createdAt
      })

      if (coupon) {
        dashboardUrl += '?coupon=' + coupon
      }

      // with each version of TOS window.servars.tos will be greater than user.tos
      // so a pop up will appear
      if (user.tos < window.servars.tos) {
        this.newTosConfirm(function (confirmed) {
          if (confirmed) {
            (new zc.models.User(user)).updateTos(window.servars.tos).then(function (res) {
              window.location.href = dashboardUrl
            }).catch(function (err) {
              utils.notify('error', err)
            })
          } else {
            window.location.pathname = '/logout'
          }
        })
      } else {
        window.location.href = dashboardUrl
      }
    },

    renderHeader: function () {
      this.recordingHeaderView = new zc.views.ProjectHeaderView({ configType: 'login', model: this.model, el: this.$header })
      this.recordingHeaderView.render()
    },

    render: function () {
      this.$el.html(this.template(this.model.toJSON()))
      this.$header = this.$('.header-container')

      this.renderHeader()
      this.renderLoginForm()

      return this
    }
  })
})()
