/* globals zc _ Backbone */

(function () {
  'use strict'

  zc.views.ConfirmView = Backbone.View.extend({
    initialize: function (options) {
      this.callback = options.callback || function () {}
    },

    template: _.template($('.confirm-template').html()),

    className: 'confirm-prompt',

    defaults: {
      title: 'Title',
      text: 'Text',
      confirmText: 'Confirm',
      cancelText: 'Cancel'
    },

    events: {
      'click .confirm': 'confirm',
      'click .cancel': 'cancel'
    },

    confirm: function () {
      this.callback(true)
    },

    cancel: function () {
      this.callback(false)
    },

    render: function () {
      var attrs = _.extend({}, this.defaults, this.model.toJSON())
      this.$el.html(this.template(attrs))
      return this
    }
  })
})()
