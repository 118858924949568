/* globals zc _ */

(function () {
  'use strict'

  zc.views.NotFoundView = zc.views.PageView.extend({

    template: _.template($('.not-found-template').html()),

    render: function () {
      this.$el.html(this.template(this.model.toJSON()))
      this.$el.addClass('error-page')
      this.$el.addClass('404')
      this.$header = this.$('.header-container')
      this.headerView = new zc.views.ProjectHeaderView({configType: 'errorPage', model: this.model, el: this.$header, message: '404 Error: Page not found'})
      this.headerView.render()

      return this
    }
  })
})()
