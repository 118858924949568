/* globals zc Backbone */

(function () {
  'use strict'

  zc.models.PaymentSource = Backbone.Model.extend({
    initialize: function (attrs) {
      this.on('change:exp_month', this.expMonthChange)
      this.on('change:exp_year', this.expYearChange)
      this.set(this.parse(attrs))
    },

    defaults: {
      last4: null,
      expMonth: null,
      expYear: null
    },

    expMonthChange: function (model, expMonth) {
      this.set({expMonth: expMonth})
    },

    expYearChange: function (model, expYear) {
      this.set({expYear: expYear})
    },

    parse: function (attrs) {
      // this is needed to handle a problem where newer payment sources are just card objects and not token objects
      // rip this out once the data is normalized in the database
      if (attrs.card) {
        attrs = attrs.card
      }
      return attrs
    }

  })
})()
