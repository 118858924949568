/* globals zc app debug servars utils jwtClient session */

/** @typedef {import('@zencastr/jwt-fetch-client')} JwtClient */

(function () {
  'use strict'

  var dbg = debug('zc')

  var appAttrs = {}
  var identityEndpoint = window.location.pathname.startsWith('/admin') ? '/admin_me' : '/me'
  jwtClient.fetchWithJwt(identityEndpoint).then(function (res) {
    if (res.status === 401 || res.status === 403) return null // user is not logged in or does not have permissions
    if (!res.ok) {
      alert('An unexpected error occurred, please try again later')
      console.error(res.responseText)
    } else {
      return res.json()
    }
  }).then(function (user) {
    if (user) {
      session.loggedIn = true
      session.user = user
      session.userId = user._id
      appAttrs.user = new zc.models.User(user)
    }
    // if we have a pipelineVersion
    if (servars.pipelineVersion) {
      if (window.trackJs) {
        window.trackJs.addMetadata('pipelineVersion', servars.pipelineVersion)
      }

      if (window.Intercom) {
        var intercomData = {
          'pipelineVersion': servars.pipelineVersion,
          'isUsingSFU': true
        }

        if (utils.hasGreenScreenIssue()) {
          intercomData.hasGreenScreenIssue = true
        }
        window.Intercom('update', intercomData)
      }
    }

    window.app = new zc.models.App(appAttrs)
    window.appView = new zc.views.AppView({ model: app, el: $('.app') })
    window.appView.render()
    window.app.start()

    if (!servars.production && dbg.enabled) {
      (function () {
        // milliseconds
        var lastTime = (new Date()).getTime()
        var acceptableDelta = 50
        var tick = 50
        var hung = false

        function hangman () {
          var now = (new Date()).getTime()
          if (now - lastTime > (tick + acceptableDelta)) {
            hung = true
          } else if (hung) {
            hung = false
            console.warn('Possible browser hangup detected.')
          }
          lastTime = now
        }

        setInterval(hangman, tick)
      })()
    }
  }, function (err) {
    console.error(err)
    alert('An unexpected error occurred, please try again later')
  })
})()
