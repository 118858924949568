/* globals zc zc2 _ Backbone app utils ua analytics jwtClient */

(function () {
  'use strict'

  var session = window.session

  zc.models.User = Backbone.Model.extend({
    initialize: function (attrs) {
      this.tracks = new zc.collections.Tracks(attrs.tracks)
      this.tentativeTracks = new zc.collections.Tracks()

      if (attrs.settings) {
        // ovewrite hostVoip setting and make it true
        attrs.settings.hostVoip = true
      }

      this.settings = new zc.models.Settings(attrs.settings, { user: this })
      this.paymentSource = new zc.models.PaymentSource(attrs.paymentSource || {}, { user: this })
      this.subscription = new zc.models.Subscription(attrs.subscription || {}, { user: this })
      this.stats = new zc.models.Stats(attrs.stats, { user: this })
      this.videoInput = new Backbone.Model(attrs.videoInput)
      this.audioInput = new Backbone.Model(attrs.audioInput)
      this.audioOutput = new Backbone.Model(attrs.audioOutput)

      // health checks
      this.criticalHealthChecks = new Backbone.Collection(attrs.criticalHealthChecks)
      this.warningHealthChecks = new Backbone.Collection(attrs.warningHealthChecks)

      this.on('change:muted', this.trackMutedChange)
      this.on('change:cameraOn', this.trackCameraChange)
      this.listenTo(this, 'voipConnected', this.voipConnected)
      this.listenTo(this, 'voipDisconnected', this.voipDisconnected)
      this.listenTo(this.tracks, 'change:percentUploaded', this.trackPercentUploadedChange)

      // only registered users will have isVerified keys
      this.set({ isRegistered: attrs.isRegistered || !_.isUndefined(attrs.isVerified) })

      // set displayName
      var fullName = attrs.firstName ? attrs.firstName + ' ' + attrs.lastName : null
      this.set({ _displayName: attrs.displayName, displayName: attrs.displayName || fullName || attrs.username })

      this.watcher = new zc2.utils.ReduxWatcher(zc2.store)
    },

    urlRoot: '/api/users',

    defaults: {
      firstName: null,
      lastName: null,
      username: 'Mr. Rogers',
      email: null,
      login: null,
      displayName: null,
      isVerified: false,
      isRegistered: false,
      greenroom: false,
      tos: 0,
      micArmed: false,
      audioInput: null,
      audioOutput: null,
      muted: false,
      cameraOn: true,
      cameraConnected: true,
      countingDown: false,
      readyToRecord: false, // set to true when fully prepared to record
      isRecording: false,
      uploading: false,
      uploadComplete: false,
      isVoipConnected: false, // used to represent users that were once in the call but are not expected back
      profilePic: '/media/images/profilepics/profile' + (Math.floor(Math.random() * 8) + 1) + '.jpeg',
      appVersion: window.servars && window.servars.version,
      userAgent: window.navigator.userAgent,
      platform: {
        sampleRate: 0,
        browser: ua.browser.name + ' ' + ua.browser.version,
        engine: ua.engine.name + ' ' + ua.engine.version,
        os: ua.os.name + ' ' + ua.os.version,
        cpu: ua.cpu.architecture,
        device: ua.device.model + ' ' + ua.device.type + ' ' + ua.device.vendor,
        hardwareConcurrency: navigator.hardwareConcurrency,
        deviceMemory: navigator.deviceMemory,
        connection: {
          downlink: navigator.connection ? navigator.connection.downlink : undefined,
          effectiveType: navigator.connection ? navigator.connection.effectiveType : undefined,
          rtt: navigator.connection ? navigator.connection.rtt : undefined
        },
        audioWorkletSupport: null // whether the recording was initialized with audioWorklet support
      },
      cloudToken: null,
      cloudQuota: {
        total: 0,
        used: 0,
        free: 0
      },
      localStorageQuota: {
        total: 0,
        used: 0,
        free: 0
      },
      status: null,
      notificationPermission: window.Notification && Notification.permission,
      storagePersistence: null,
      onetimePostproductionCredits: 0,
      fps: 60 // frames per second that the animations will adhere to.  Can be lowered for legacy hardwar to improve performance
    },

    attrs: function () {
      var attrs = this.toJSON()
      attrs.model = this
      attrs.cloudToken = this.getCloudToken()
      attrs.micLabel = this.formatMicLabel(this.audioInput.get('label'))
      attrs.subscription = this.subscription
      attrs.settings = this.settings
      attrs.healthCheckStatus = this.getHealthCheckStatus()
      return attrs
    },

    toExtendedJSON: function () {
      var state = zc2.store.getState()
      var devices = state.media_manager.devices
      var preferredDevices = state.media_manager.preferredDevices
      var preferredAudioInput = preferredDevices.audioInput ? preferredDevices.audioInput.deviceId : null
      var preferredAudioOuput = preferredDevices.audioOutput ? preferredDevices.audioOutput.deviceId : null
      var preferredVideoInput = preferredDevices.videoInput ? preferredDevices.videoInput.deviceId : null

      var json = this.toJSON()
      json.audioInput = devices.find(function (d) { return d.deviceId === preferredAudioInput && d.kind === 'audioinput' })
      json.audioOutput = devices.find(function (d) { return d.deviceId === preferredAudioOuput && d.kind === 'audiooutput' })
      json.videoInput = devices.find(function (d) { return d.deviceId === preferredVideoInput && d.kind === 'videoinput' })
      json.tracks = this.tracks.toJSON() // so any already created tracks get shared on room join
      json.warningHealthChecks = this.warningHealthChecks.toJSON()
      json.criticalHealthChecks = this.criticalHealthChecks.toJSON()
      json.settings = this.settings.toJSON()
      json.isHost = this.isHost()
      json.pipelineVersion = window.servars.pipelineVersion || ''

      return json
    },

    getHealthCheckStatus: function () {
      if (!this.criticalHealthChecks || !this.criticalHealthChecks.length) return 'pending'
      if (this.criticalHealthChecks.pluck('passed').indexOf(false) > -1) return 'failed'
      if (!this.warningHealthChecks || !this.warningHealthChecks.length) return 'pending'
      if (this.warningHealthChecks.pluck('passed').indexOf(false) > -1) return 'warning'
      return 'passed'
    },

    destroy: function () {
      this.watcher.destroy()
    },

    trackMutedChange: function (model, muted) {
      if (model.id === app.user.id) {
        zc2.store.dispatch(zc2.actions.users.UPDATE_LOCAL_USER_MUTED_STATUS(muted))
      }

      analytics.track(
        'MuteChange',
        {
          muted: muted,
          userId: app.user.id,
          projectId: app.project.id,
          recordingId: app.project.recorder.recording.id
        }
      )
    },

    addWatchers: function () {
      if (!this.watchersExist) {
        this.watcher.watch('users.localUser.cameraConnected', this.localCameraConnectedChange.bind(this))
        if (this.isLocal()) {
          this.watcher.watch('media_manager.preferredDevices', function (devices) {
            this.audioInput.set(devices.audioInput || {})
            this.videoInput.set(devices.videoInput || {})
            this.audioOutput.set(devices.audioOutput || {})
          }.bind(this))
        }
        this.watchersExist = true
      }
    },

    localCameraConnectedChange: function (connected) {
      this.set('cameraConnected', connected)
      app.socket.emit('user:change:cameraConnected', { userId: this.id, cameraConnected: connected })
    },

    trackCameraChange: function (model, cameraOn) {
      if (model.id === app.user.id) {
        zc2.store.dispatch(zc2.actions.users.UPDATE_LOCAL_USER_CAMERA_STATUS(cameraOn))
      }

      analytics.track('CameraOnChange', {
        cameraOn: cameraOn,
        projectId: app.location.id,
        recordingId: app.location.recorder.recording.id,
        userId: app.user.id,
        username: app.user.get('username')
      })
    },

    didPassHealthCheck: function () {
      var passed = false
      var status = this.getHealthCheckStatus()

      var nonPassStates = ['pending', 'failed']

      if (nonPassStates.indexOf(status) < 0) {
        passed = true
      }

      return passed
    },

    clearHealthChecks: function () {
      this.warningHealthChecks.reset()
      this.criticalHealthChecks.reset()
    },

    trackPercentUploadedChange: function (track, percentUploaded) {
      var isMp3 = track.get('format') === 'mp3'
      if (isMp3) {
        this.set({ cloudBackupPercent: percentUploaded })
      }
    },

    incPostproductionCredits: function (num) {
      var credits = this.get('onetimePostproductionCredits')
      this.set({ onetimePostproductionCredits: credits + num })
    },

    formatMicLabel: function (label) {
      if (label === 'Default') label = 'System Default Microphone'
      if (!label) label = 'Unknown'
      return label
    },

    getFeature: function (featureKey) {
      var plan = this.subscription.plan
      return plan.get('features')[featureKey]
    },

    getCloudToken: function () {
      return this.get('cloudToken')
    },

    // set the user as logged in on the session
    setLoggedIn: function () {
      session.loggedIn = true
      session.userId = this.id
      // session.user = this.toJSON()
    },

    // set the user as logged out on the session
    setLoggedOut: function () {
      session.loggedIn = false
      session.userId = null
      // session.user = null
    },

    loggedIn: function () {
      if ((app.user === this) && session.loggedIn) {
        return true
      } else {
        return false
      }
    },

    getUsedPostproductionCredits: function () {
      return this.stats.get('usedCredits')
    },

    getAvailablePostproductionCredits: function () {
      var user = this

      // calc what is left of monthly recurring credits
      var usedCredits = user.getUsedPostproductionCredits()
      var availableCredits = user.getFeature('recurringPostproductionCredits') - usedCredits

      // minimum of zero available.  You can't go into debt on credits.
      availableCredits = Math.max(availableCredits, 0)

      // add one-time credits.  One credit is worth one hour
      availableCredits += user.get('onetimePostproductionCredits')

      return availableCredits
    },

    getTotalPostproductionCredits: function () {
      var recurringCredits = this.subscription.plan.get('features').recurringPostproductionCredits
      var onetimeCredits = this.get('onetimePostproductionCredits')
      var totalCredits = recurringCredits + onetimeCredits
      return totalCredits
    },

    getTrack: function (format, type) {
      type = type || 'microphone'
      return this.tracks.filter({ format: format, type: type })[0]
    },

    // Creates a new track owned by this user both on the frontend and server side
    // then adds the user.tracks collection which will cause it to render in the UI
    createTrack: function (attrs) {
      var self = this
      return new Promise(function (resolve, reject) {
        var project = app.location
        var recording = project.recorder.recording

        var host = app.location.lobby.getHost()
        var cloudToken = host ? host.get('cloudToken') : null

        if (cloudToken) {
          attrs.token = cloudToken.token
          attrs.cloudDrive = cloudToken.provider
        }

        attrs.hostId = project.get('ownerId')
        attrs.hostUsername = project.get('owner')
        attrs.projectId = project.id
        attrs.projectSlug = project.get('slug')
        attrs.recordingId = recording.id
        attrs.recordingSlug = 'recording-' + (project.recordings.indexOf(recording) + 1)
        attrs.userId = self.id
        attrs.username = self.get('username')

        app.socket.emit('upload:create', attrs, function (err, uploadAttrs) {
          if (err) { utils.notify('error', err); return reject(err) }

          // we do not keep track of the uploading state server side
          // so when we create the track, we need to manually set it here
          uploadAttrs.uploading = true

          var track = new zc.models.Track(uploadAttrs)
          self.tracks.add(track)
          resolve(track)
        })
      }).catch(utils.logRethrow)
    },

    isHost: function () {
      // the host is defined by who is the owner of the project
      return app.location && this.id === app.location.get('ownerId')
    },

    isLocal: function () {
      return this.id === app.user.id
    },

    ownsTrack: function (track) {
      return this.id === track.get('userId')
    },

    hasFreePlan: function () {
      return ['hobbyist', 'hobbyist_yearly'].indexOf(this.subscription.plan.get('billingId')) > -1
    },

    // canControlRecording: function () {
    //   // only hosts can control recording unless the
    //   // guest has become disconnected from the host
    //   // while recording is taking place
    //   return this.isHost() || (this.get('recording') && !app.socket.connected)
    // },

    areAllTracksUploaded: function () {
      var project = app.location

      if (!this.tracks.length) return false

      var sucessfullyUploadedTracks = this.tracks.map(function (track) {
        return track.get('finalized') || track.get('processing')
      })

      // get all the tracks that are not finalized yet
      // they might appear here if the user refresh the page
      var userUnfinalizedTracks = project.recorder.recording.tracks.filter(function (track) {
        return track.get('userId') === app.user.get('_id') && !track.get('finalized') && !track.get('processing') && !track.get('uploading')
      })

      var allUploaded = sucessfullyUploadedTracks.indexOf(false) < 0 && userUnfinalizedTracks.length === 0

      return allUploaded
    },

    updateTos: function (newTosVersion) {
      var self = this
      this.trigger('loading')
      return new Promise(function (resolve, reject) {
        /** @type {import('@zencastr/jwt-fetch-client')} */ (jwtClient)
          .fetchWithJwt('/api/users/' + this.id, {
            method: 'PUT',
            body: JSON.stringify({
              tos: newTosVersion
            }),
            headers: { 'Content-Type': 'application/json' }
          }).then(function (res) {
            if (res.ok) {
              resolve(res.json())
            } else {
              res.text().then(function (responseText) {
                utils.notify('error', responseText)
                reject(responseText)
              })
            }
          }).finally(function () {
            self.trigger('doneLoading')
          })
      })
    },

    voipConnected: function () {
      this.set({ isVoipConnected: true })
    },

    voipDisconnected: function () {
      this.set({ isVoipConnected: false })
    },

    getStatus: function () {
      var status = null
      var recorder = app.project.recorder

      var attrs = this.attrs()
      if (attrs.greenroom) {
        status = 'In Green Room'
      } else if (this.areAllTracksUploaded()) {
        status = 'All tracks uploaded'
      } else if (attrs.micArmed) {
        if (this.get('paused')) {
          status = 'Paused'
        } else if (attrs.isRecording) {
          status = 'Recording in progress'
        } else if (recorder.hasFinishedRecording()) {
          status = 'This recording has finished'
        } else if (attrs.healthCheckStatus === 'pending') {
          // if this is a guest
          var userIsHost = this.isHost()
          var isHostPresent = !!app.location.lobby.getHost()
          var waitingForHost = (!userIsHost && !isHostPresent)
          status = waitingForHost ? 'Waiting for host to join' : 'Running health checks'
        } else if (attrs.healthCheckStatus === 'failed') {
          status = 'Health check failed - Unable to record'
        } else if (attrs.healthCheckStatus === 'warning') {
          status = 'Health check passed with warnings'
        } else if (attrs.healthCheckStatus === 'passed') {
          status = 'Health check passed - Ready to record'
        }
      } else {
        status = 'Waiting for microphone access'
      }

      return status
    }
  })
})()
