/* globals zc _ jwtClient */

(function () {
  'use strict'

  zc.views.LoginFormView = zc.views.FormView.extend({
    template: _.template($('.login-form-template').html()),

    className: 'login-form form',

    values: function () {
      return {
        login: this.$username.val().toLowerCase(),
        password: this.$password.val()
      }
    },

    url: function () {
      return '/api/login'
    },

    render: function () {
      var view = this
      var attrs = this.model ? this.model.toJSON() : {}
      this.$el.html(this.template(attrs))
      this.$error = this.$('.error')
      this.$username = this.$('.username')
      this.$password = this.$('.password')

      _.defer(function () {
        view.$username.focus()
      })

      return this
    },

    submit: function (e) {
      if (e) {
        e.preventDefault()
        e.stopPropagation()
      }
      var view = this
      var values = this.values()

      this.showLoading()

      jwtClient.login(values.login, values.password).then(function (res) {
        if (!res.ok && res.status === 400) {
          view.showError('Invalid username/email or password')
        } else {
          view.callback(null, res)
        }
      }, function (err) {
        console.error('error', err)
        view.showError('We are having difficulties, please try again later')
      }).finally(function () {
        view.hideLoading()
      })
    }
  })
})()
