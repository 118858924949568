/* globals zc _ servars */

(function () {
  'use strict'

  zc.views.MovingBackgroundView = zc.views.BaseView.extend({
    template: _.template('<canvas class="moving-bg takeover" width="2" height="2"></canvas>'),
    /**
     * @param retry {boolean} Indicates if retry logic should be used to render. Defaults true.
     */
    startAnimation: function (retry = true) {
      var canvas = this.$el.find('canvas').get(0)
      let ctx
      if (this.canvasWorker) return

      try {
        ctx = canvas.transferControlToOffscreen()
      } catch (e) {
        // There is an error transferring control from a canvas more than once
        // If we encounter that error, recreate the canvas, else, throw error
        if (e instanceof DOMException && e.name === 'InvalidStateError' && retry) {
          this.render()
          return this.startAnimation(false)
        }
        throw e
      }
      this.canvasWorker = new Worker(servars.movingBackgroundWorker)

      this.canvasWorker.postMessage({
        command: 'add-canvas',
        canvas: ctx
      }, [ctx])
    },
    stopAnimation: function () {
      if (this.canvasWorker) {
        this.canvasWorker.terminate()
      }
    },
    render: function () {
      if (this.model.get('background')) {
        this.$el.addClass('fix')
      }
      this._render()
    }
  })
})()
